import styled from 'styled-components';

const LoaderStyles = styled.div`
  background: transparent;
  .ant-spin-blur {
  }
  .ant-spin-dot {
    width: 2em;
    height: 2em;
    font-size: 28px;
  }
  .ant-spin-dot-item {
    width: 35px;
    height: 35px;
  }
`;

export default LoaderStyles;
