import axios from 'axios';

const services = {
  getBearerToken: () => axios({
    method: 'post',
    url: 'https://fam.debt.com/signin',
    data: { url: 'debt.com/land', pid: '4654656' },
  }),
  verifyZipCode: (country, zipCode) => axios.get(`https://zip.getziptastic.com/v2/${country}/${zipCode}?_=1544457763845`),
  createTicketJira: (payload) => axios({
    method: 'post',
    url: 'https://dcom-api-validator.debt.com/create-ticket',
    data: payload,
    headers: { Authorization: 'Api-Key R0d0NjVLS08hISEvPz80NDExMjU0OHVVSkhKaGdkd1BPPExt' },
  }),
  sendEmailToUser: (payload) => axios({
    method: 'post',
    url: 'https://dcom-api-validator.debt.com/send-email',
    data: payload,
    headers: {
      Authorization: 'Api-Key R0d0NjVLS08hISEvPz80NDExMjU0OHVVSkhKaGdkd1BPPExt',
      'Content-Type': 'application/json',
    },
  }),
  sendDataFam: (payload, token) => axios({
    method: 'post',
    url: 'https://fam.debt.com/api/v1/helpline',
    data: payload,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }),
  // creditSaint: (payload, token) => axios({
  //   method: 'post',
  //   url: 'https://fam.debt.com/api/v1/credit-saint',
  //   data: payload,
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json',
  //   },
  // }),
};

export default services;
