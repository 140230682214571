import styled from 'styled-components';
import fromTheme from '../../helpers/fromTheme';

const ATEFormStyles = styled.div`
  background-color: transparent;
  h3 {
    margin-bottom: 25px;
    font-size: ${(props) => fromTheme(props, 'fontSizes', 'regularCopy')};
    font-weight: ${(props) => fromTheme(props, 'fontWeights', 'heading')};
  }
  .ant-col:first-of-type {
    border-right: none !important;
  }
  .ant-form-item-control {
    min-width: 100%;
  }
  .ant-form-item-label {
    padding-bottom: 0;
    label {
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 5px;
    }
  }
  .ant-input-number {
    width: 100%;
    .ant-input-number-handler-wrap {
      display: none;
    }
  }
  .form-btn {
    min-width: 80px;
    color: #fff;
    background-color: ${(props) => fromTheme(props, 'colors', 'primary')};
    transition: all 250ms ease;
    border-radius: 5px;
    border: 0;
    &:hover {
      background-color: ${(props) => fromTheme(props, 'colors', 'primaryDarker')};
    }
  }
  .form-btns-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btns-wrapper-centered {
      width: 190px;
      display: block;
      margin: 0 auto;
    }
    button {
      min-width: 80px;
      &:nth-of-type(1) {
        margin-right: 10px;
        border-color: #2a353d;
        color: #5a5a5a;
        transition: all 250ms ease;
        border-radius: 5px;
        &:hover {
          background-color: #2a353d;
          color: #fff;
        }
      }
      &:nth-of-type(2) {
        margin-left: 10px;
        border-radius: 5px;
      }
    }
  }
  .form-disclaimer {
    .disclaimer-content {
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      a {
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
      }
    }
    strong {
      color: #000;
    }
  }
  .final-message {
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 15px;
    h3 {
      font-size: 20px;
    }
    p {
      font-size: 16px;
    }
    strong {
      color: #000;
      font-weight: 800;
    }
    button {
      background-color: ${(props) => fromTheme(props, 'colors', 'primary')};
      &:hover {
        background-color: ${(props) => fromTheme(props, 'colors', 'primaryDarker')};
      }
    }
  }
  @media all and (max-width: 786px) {
    padding-top: 30px;
    .ant-form-item-explain {
      text-align: left;
    }
    .form-btns-wrapper {
      margin: 20px auto;
    }
  }
`;

export default ATEFormStyles;
