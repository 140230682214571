import React from 'react';
import PropTypes from 'prop-types';

const FormDisclaimer = ({ lang }) => (lang === 'en' ? (
  <div className="form-disclaimer">
    <p className="disclaimer-content">
      <strong>Disclaimer: </strong>
      By clicking on the &quot;Submit&quot; button above, you consent, acknowledge, and agree to
      the following: Our
      <a
        href="https://www.debt.com/terms-conditions/"
        target="_blank"
        rel="noopener noreferrer nofollower"
      >
          &nbsp;Terms of Use&nbsp;
      </a>
      and
      <a
        href="https://www.debt.com/privacy-policy/"
        target="_blank"
        rel="noopener noreferrer nofollower"
      >
          &nbsp;Privacy Policy
      </a>
      . That you are providing express &quot;written&quot; consent for
      <a href="https://www.debt.com" target="_blank" rel="noopener noreferrer nofollower">
          &nbsp;Debt.com&nbsp;
      </a>
      or appropriate
      <a
        href="https://www.debt.com/privacy-policy/partners/"
        target="_blank"
        rel="noopener noreferrer nofollower"
      >
          &nbsp;service provider(s)&nbsp;
      </a>
      to call you (autodialing, text and pre-recorded messaging for convenience) via telephone,
      mobile device (including SMS and MMS - charges may apply depending on your carrier, not by
      us), even if your telephone number is currently listed on any internal, corporate, state or
      federal Do-Not-Call list. We take your privacy seriously and you may receive electronic
      communications, including periodic emails with important news, financial tips, tools and
      more. You can always unsubscribe at any time. Consent is not required as a condition to
      utilize
      <a href="https://www.debt.com/" target="_blank" rel="noopener noreferrer nofollower">
          &nbsp;Debt.com&nbsp;
      </a>
      services and you are under no obligation to purchase anything
    </p>
  </div>
) : (
  <div className="form-disclaimer">
    <p className="disclaimer-content">
      <strong>Aviso legal:&nbsp;</strong>
      Al hacer click en el botón &quot;Enviar&quot; que se muestra arriba, usted consiente,
      comprende y acepta lo siguiente: nuestros
      <a
        href="https://www.debt.com/es/terminos-y-condiciones/"
        target="_blank"
        rel="noopener noreferrer nofollower"
      >
          &nbsp;Términos de uso&nbsp;
      </a>
      y
      <a
        href="https://www.debt.com/es/politicas-de-privacidad/"
        target="_blank"
        rel="noopener noreferrer nofollower"
      >
          &nbsp;Políticas de privacidad
      </a>
      . Ya que nos está proporcionando el consentimiento &quot;escrito&quot; expreso para que
      <a href="https://www.debt.com/es/" target="_blank" rel="noopener noreferrer nofollower">
          &nbsp;Debt.com&nbsp;
      </a>
      o los proveedores de
      <a
        href="https://www.debt.com/es/politicas-de-privacidad/socios/"
        target="_blank"
        rel="noopener noreferrer nofollower"
      >
          &nbsp;servicios aprobados&nbsp;
      </a>
      puedan llamarlo (llamadas automáticas, mensajes de textos y mensajes pregrabados por
      conveniencia) vía telefónica, dispositivo móvil (incluyendo mensajes SMS y MMS – cargos
      pueden ser aplicables según su proveedor de servicio, no por nosotros), incluso si su número
      telefónico está actualmente en alguna lista interna, corporativa, estatal o en listas
      federales de “No llamar”. Nosotros tomamos su privacidad en serio y usted puede que reciba
      comunicaciones electrónicas, incluyendo correos electrónicos periódicamente con noticias
      importantes, consejos financieros, herramientas y más. Usted puede cancelar su suscripción
      en cualquier momento. El consentimiento no tiene como condición que usted utilice los
      servicios de
      <a href="https://www.debt.com/es/" target="_blank" rel="noopener noreferrer nofollower">
          &nbsp;Debt.com&nbsp;
      </a>
      o que está obligado a comprar algo.
    </p>
  </div>
));

FormDisclaimer.propTypes = {
  lang: PropTypes.string.isRequired,
};
export default FormDisclaimer;
