import React from 'react';
import ReactDOM from 'react-dom';

import { Button } from 'antd';
import CredibilityModalStyles from './CredibilityModal.styles';

const scrollDown = () => {
  const scrollOptions = {
    top: 800,
    left: 0,
    behavior: 'smooth',
  };

  if (window.innerWidth >= 320 && window.innerWidth <= 374) {
    scrollOptions.top = 1860;
  } else if (window.innerWidth >= 375 && window.innerWidth <= 410) {
    scrollOptions.top = 1730;
  } else if (window.innerWidth >= 411 && window.innerWidth <= 500) {
    scrollOptions.top = 1630;
  } else if (window.innerWidth >= 501 && window.innerWidth <= 640) {
    scrollOptions.top = 1415;
  } else if (window.innerWidth >= 641 && window.innerWidth <= 736) {
    scrollOptions.top = 1405;
  } else if (window.innerWidth >= 737 && window.innerWidth <= 812) {
    scrollOptions.top = 950;
  }

  window.scrollBy(scrollOptions);
};

const CredibilityModal = ({
  hide,
  brand,
  bbbLogo,
  bbbCopy,
  showModal,
  modalPhone,
  buttonCopy,
  modalPhoneNum,
  unitedWayCopy,
  unitedwayLogo,
}) => (showModal
  ? ReactDOM.createPortal(
    <CredibilityModalStyles>
      <div className="modal-overlay" />
      <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
        <div className="modal">
          <div className="modal-header">
            <button
              type="button"
              className="modal-close-button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={hide}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-content">
            {brand === 'bbb' && <img src={bbbLogo} alt="debt.com logo" />}
            {brand === 'united-way' && <img src={unitedwayLogo} alt="debt.com logo" />}

            {brand === 'bbb' && bbbCopy}
            {brand === 'united-way' && unitedWayCopy}

            <p className="modal-phone-copy">
              <a href={`tel:+${modalPhoneNum}`}>{modalPhone}</a>
            </p>
            <Button
              onClick={() => {
                scrollDown();
                hide();
              }}
              className="modal-button"
            >
              {buttonCopy}
            </Button>
          </div>
        </div>
      </div>
    </CredibilityModalStyles>,
    document.body,
  )
  : null);

export default CredibilityModal;
