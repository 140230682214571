import { useState } from 'react';

const useModal = () => {
  const [showModal, setShowModal] = useState(false);

  function toggleModal() {
    setShowModal(!showModal);

    if (showModal !== true) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }

  return {
    showModal,
    toggleModal,
  };
};

export default useModal;
