/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Input, Button, Select, Carousel, InputNumber, Row, Col,
} from 'antd';
import axios from 'axios';
import services from '../../services/services';
import Loader from '../Loader/Loader';
import FormDisclaimer from './FormDisclaimer';
import QuestionFormStyles from './ATEForm.styles';
import { fromUrlParamToObj } from '../../helpers/helpers';

const { TextArea } = Input;
const { Option } = Select;

function ATEForm({ data, lang, phoneData }) {
  const [isLoading, setLoading] = useState(false);
  // const [userState, setUserState] = useState('');
  const [bearerToken, setBearerToken] = useState('');
  const carouselRef = useRef(null);
  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    services
      .getBearerToken()
      .then((resp) => {
        setLoading(false);
        setBearerToken(resp.data.token);
      })
      .catch(() => {
        setLoading(false);
        carouselRef.current.goTo(3, false);
      });
  }, []);

  const goToSecondForm = () => {
    form.validateFields(['topic', 'question']).then(() => {
      carouselRef.current.next();
    });
  };

  const goToFirstForm = () => {
    carouselRef.current.goTo(0, false);
  };

  async function validateZipCode(_, value) {
    if (value.length !== 5) {
      return Promise.reject('error menor que 5');
    }
    const checkZipCode = await services
      .verifyZipCode('US', value)
      .then((respUs) => respUs)
      .catch(() => services.verifyZipCode('PR', value).then((respPR) => respPR))
      .catch(() => false);

    if (checkZipCode) {
      return Promise.resolve();
    }
    return Promise.reject('Invalid zip code');
  }

  const handleSubmit = (values) => {
    const {
      topic, question, firstName, lastName, email, zipCode, phone,
    } = values;
    const url = window !== 'undefined' ? `${window.location.hostname}${window.location.pathname || ''}` : '';
    const urlParams = window !== 'undefined' && window.location.search.substring(1).length !== 0
      ? fromUrlParamToObj(window.location.search.substring(1))
      : {};
    // prepare payloads
    const jiraPayload = {
      category: topic,
      subject: 'Ask The expert question',
      question,
      fullName: `${firstName} ${lastName}`,
      email,
      zipCode,
      phone,
    };
    const emailPayload = {
      ...jiraPayload,
      source: typeof window !== 'undefined' ? window.location.href : null,
    };
    const famPayload = {
      category: topic,
      subject: 'Ask The expert question',
      question,
      firstName,
      lastName,
      email,
      zipCode,
      phone,
      url,
      language: lang.toUpperCase(),
      urlParams: String(JSON.stringify(urlParams)),
    };
    // const creditSaintPayload = {
    //   f_source: '1181',
    //   f_name: firstName,
    //   f_last_name: lastName,
    //   f_state: userState,
    //   f_phone: `${phone}`,
    //   f_email: email,
    //   landing: '0',
    //   sub_id: '',
    //   sub_id2: '',
    // };
    // start loader
    setLoading(true);
    // multi-requests to Jira and Email API
    axios
      .all([
        services.sendEmailToUser(emailPayload),
        services.createTicketJira(jiraPayload),
        services.sendDataFam(famPayload, bearerToken),
      ])
      .then(
        axios.spread(() => {
          carouselRef.current.goTo(2, false); // show success message in the slide #3
          if (typeof window !== 'undefined') {
            window.Helpline = window.Helpline || [];
            window.Helpline.push({ event: 'LandingPageSubmissionSuccess' });
          }
          setLoading(false);
        }),
      )
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        carouselRef.current.goTo(3, false); // show error message in the slide #4
        setLoading(false);
      });
  };

  const handleFailedValidation = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };
  const {
    headline,
    topic,
    questions,
    nextBtn,
    name,
    lastname,
    email,
    zipField,
    phoneField,
    backBtn,
    submitBtn,
    successMsg,
    errorMsg,
  } = data;
  return (
    <QuestionFormStyles>
      <Loader isLoading={isLoading}>
        <h3>{headline}</h3>
        <Form
          layout="vertical"
          form={form}
          hideRequiredMark
          onFinish={handleSubmit}
          onFinishFailed={handleFailedValidation}
        >
          <Carousel
            effect="fade"
            adaptiveHeight
            dots={false}
            swipe={false}
            touchMove={false}
            ref={carouselRef}
          >
            <div className="slide-1">
              <Row>
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                >
                  <Form.Item
                    label={topic.label}
                    name="topic"
                    rules={[{ required: true, message: topic.error }]}
                    initialValue={topic.options.find((option) => option.isDefault).value}
                  >
                    <Select style={{ textAlign: 'left' }}>
                      {topic.options.map(({ id, value, text }) => (
                        <Option key={id} value={value}>
                          {text}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={{ span: 24, offset: 0 }}
                  sm={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                >
                  <Form.Item
                    label={questions.label}
                    rules={[{ required: true, message: questions.errorRequired }]}
                    name="question"
                  >
                    <TextArea autoSize={{ minRows: 6, maxRows: 10 }} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <Button type="primary" onClick={goToSecondForm} className="form-btn">
                  {nextBtn}
                </Button>
              </Form.Item>
            </div>
            <div className="slide-2">
              <Row>
                <Col
                  xs={{ span: 22, offset: 1 }}
                  sm={{ span: 16, offset: 4 }}
                  md={{ span: 22, offset: 1 }}
                  lg={{ span: 11, offset: 0 }}
                >
                  <Form.Item
                    label={name.label}
                    name="firstName"
                    hasFeedback
                    rules={[
                      { required: true, message: name.errorRequired },
                      { pattern: /^[a-zA-Z]+$/, message: name.otherError },
                    ]}
                  >
                    <Input autoComplete="no" />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 22, offset: 1 }}
                  sm={{ span: 16, offset: 4 }}
                  md={{ span: 22, offset: 1 }}
                  lg={{ span: 11, offset: 2 }}
                >
                  <Form.Item
                    label={lastname.label}
                    name="lastName"
                    hasFeedback
                    rules={[
                      { required: true, message: lastname.errorRequired },
                      { pattern: /^[a-zA-Z]+$/, message: lastname.otherError },
                    ]}
                  >
                    <Input autoComplete="no" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={{ span: 22, offset: 1 }}
                  sm={{ span: 16, offset: 4 }}
                  md={{ span: 22, offset: 1 }}
                  lg={{ span: 11, offset: 0 }}
                >
                  <Form.Item
                    label={email.label}
                    name="email"
                    hasFeedback
                    rules={[
                      { required: true, message: email.errorRequired },
                      { type: 'email', message: email.otherError },
                    ]}
                  >
                    <Input autoComplete="no" />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 22, offset: 1 }}
                  sm={{ span: 16, offset: 4 }}
                  md={{ span: 22, offset: 1 }}
                  lg={{ span: 11, offset: 2 }}
                >
                  <Form.Item
                    label={zipField.label}
                    name="zipCode"
                    validateFirst
                    hasFeedback
                    rules={[
                      { required: true, message: zipField.errorRequired },
                      { pattern: /^\d+$/, message: zipField.otherError },
                      { min: 5, message: zipField.otherError },
                      { validator: validateZipCode, message: zipField.otherError },
                    ]}
                  >
                    <Input autoComplete="no" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={{ span: 22, offset: 1 }}
                  sm={{ span: 16, offset: 4 }}
                  md={{ span: 22, offset: 1 }}
                  lg={{ span: 11, offset: 0 }}
                >
                  <Form.Item
                    label={phoneField.label}
                    name="phone"
                    hasFeedback
                    normalize={(value) => `${value}`}
                    rules={[
                      { required: true, message: phoneField.errorRequired },
                      {
                        pattern: /^(\d{3})(\d{3})(\d{4})$/,
                        message: phoneField.otherError,
                      },
                    ]}
                  >
                    <InputNumber
                      formatter={(value) => {
                        const input = value.replace(/\D/g, '').substring(0, 10);
                        const zip = input.substring(0, 3);
                        const middle = input.substring(3, 6);
                        const last = input.substring(6, 10);
                        if (input.length > 6) {
                          return `(${zip}) ${middle}-${last}`;
                        }
                        if (input.length > 3) {
                          return `(${zip}) ${middle}`;
                        }
                        if (input.length > 0) {
                          return `(${zip}`;
                        }
                        return '';
                      }}
                      parser={(value) => `${value}`.replace(/[- )(]/g, '').substring(0, 10)}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                wrapperCol={{
                  xs: { span: 22, offset: 0 },
                  sm: { span: 16, offset: 0 },
                  md: { span: 13, offset: 0 },
                }}
                className="form-btns-wrapper"
              >
                <div className="btns-wrapper-centered">
                  <Button type="ghost" onClick={() => carouselRef.current.prev()}>
                    {backBtn}
                  </Button>
                  <Button type="primary" htmlType="submit" className="form-btn">
                    {submitBtn}
                  </Button>
                </div>
              </Form.Item>
              <FormDisclaimer lang={lang} />
            </div>
            <div className="slide-3">{successMsg(phoneData)}</div>
            <div className="slide-4">
              <div className="final-message">
                {errorMsg(phoneData)}
                <Button type="primary" onClick={goToFirstForm}>
                  Start again
                </Button>
              </div>
            </div>
          </Carousel>
        </Form>
      </Loader>
    </QuestionFormStyles>
  );
}

ATEForm.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  phoneData: PropTypes.objectOf(PropTypes.any).isRequired,
  lang: PropTypes.string.isRequired,
};

export default ATEForm;
