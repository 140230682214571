// Parsing URL params
function getUrlParameter(name) {
  // eslint-disable-next-line no-useless-escape
  const newName = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${newName}=([^&#]*)`);
  const results = typeof window !== 'undefined' ? regex.exec(window.location.search) : null;
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

const fromUrlParamToObj = (search) => JSON.parse(`{"${search.replace(/&/g, '","').replace(/=/g, '":"')}"}`, (key, value) => (key === '' ? value : decodeURIComponent(value)));

function getPagePhone(pid, lang) {
  if (lang === 'en') {
    switch (pid) {
      case '99429':
        return { text: '866.203.DEBT (3328)', number: '18662033328' };
      case '99437':
        return { text: '(844) 310-9737', number: '18443109737' };
      case '99446':
        return { text: '(844) 325-8468', number: '18443258468' };
      case '99449':
        return { text: '(844) 978-0332', number: '18449780332' };
      case '99516':
        return { text: '(844)-311-6940', number: '18443116940' };
      case '99517':
        return { text: '(844)-262-8180', number: '18442628180' };
      default:
        return { text: '800.849.DEBT (3328)', number: '18008493328' };
    }
  }
  if (lang === 'es') {
    switch (pid) {
      case '99423':
        return { text: '(888)-99-DEUDA (33832)', number: '18889933832' }; // organic
      case '99490':
        return { text: '(888)-60-DEUDA (33832)', number: '18886033832' }; // AI Media
      case '99474':
        return { text: '(888)-81-DEUDA (33832)', number: '18888133832' }; // Oath
      case '99471':
        return { text: '(888)-98-DEUDA (33832)', number: '18889833832' }; // Facebook
      case '99500':
        return { text: '(888)-58-DEUDA (33832)', number: '18885833832' }; // Oath Programmatic
      case '99508':
        return { text: '(888)-21-DEUDA (33832)', number: '18882133832' }; // YouTube
      default:
        return { text: '(888)-99-DEUDA (33832)', number: '18889933832' };
    }
  }
  return { text: 'No number', number: '18889933832' };
}

const getPhonePht = (pid) => {
  switch (pid) {
    case '99587':
      return { text: '(844) 292-3036', number: '18442923036' };
    case '99577':
      return { text: '(844) 294-8018', number: '18442948018' };
    case '99575':
      return { text: '(844) 452-5121', number: '18444525121' };
    case '99573':
      return { text: '(844) 311-2294', number: '18443112294' };
    case '99572':
      return { text: '(844) 334-8003', number: '18443348003' };
    case '99569':
      return { text: '(844) 235-3307', number: '18442353307' };
    case '99563':
      return { text: '(844) 332-3861', number: '18443323861' };
    default:
      return { text: '(844) 235-3307', number: '18442353307' };
  }
};

const getPhoneNiroc = (pid) => {
  switch (pid) {
    default:
      return { text: '(855) 244-4692 ', number: '18552444692' };
  }
};

const getPhoneAepi = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 833-1658', number: '18448331658' };
  }
};

const getPhonesecuringlife = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 515-2218', number: '18445152218' };
  }
};

const getPhonepanda = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 325-8914', number: '18443258914' };
  }
};

const getPhoneLegalclub = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 945-0303', number: '18449450303' };
  }
};

const getPhoneHughesbc = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 400-8141', number: '18444008141' };
  }
};

const getPhoneFortunebc = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 410-2499', number: '18444102499' };
  }
};

const getPhoneUSAMedicalStaff = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 402-3975', number: '18444023975' };
  }
};

const getPhoneHandyInc = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 402-1335', number: '18444021335' };
  }
};

const getPhoneRebuildingTogether = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 326-4171', number: '18443264171' };
  }
};

const getPhoneCheddarCap = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 326-4171', number: '18443264171' };
  }
};

const getPhoneResourcingEdge = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 402-3217', number: '18444023217' };
  }
};

const getPhoneGildasClub = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 432-0266', number: '18444320266' };
  }
};

const getPhoneShieldAdvisory = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 906-1015', number: '18449061015' };
  }
};

const getPhoneMylife = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 515-2613', number: '18445152613' };
  }
};

const getPhoneMoneyVehicle = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 908-9556', number: '18449089556' };
  }
};

const getPhoneQuadMeets = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 202-7004', number: '18442027004' };
  }
};

const getPhoneEdwardJones = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 324-0984', number: '18443240984' };
  }
};

const getPhoneCoAdvantage = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 329-7231', number: '18443297231' };
  }
};

const getPhoneBillFixers = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 325-9093', number: '18443259093' };
  }
};

const getPhoneKiddieKredit = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 402-1146', number: '18444021146' };
  }
};

const getPhoneQoins = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 285-0106', number: '18442850106' };
  }
};

const getPhoneBudgetnista = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 334-2401', number: '18443342401' };
  }
};

const getPhonetTehFinancial = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 877-4756', number: '18448774756' };
  }
};

const getPhoneRichard = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 591-6003', number: '18445916003' };
  }
};

const getPhonefinancialwellnesswithvicki = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 844-2543', number: '18448442543' };
  }
};


const getPhoneMamaandmoney = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 436-0231', number: '18444360231' };
  }
};


const getPhoneInspiredbudget = (pid) => {
  switch (pid) {
    default:
      return { text: '(844) 940-1703', number: '18449401703' };
  }
};

export {
  getUrlParameter,
  fromUrlParamToObj,
  getPagePhone,
  getPhonePht,
  getPhoneMylife,
  getPhoneNiroc,
  getPhoneAepi,
  getPhonesecuringlife,
  getPhonepanda,
  getPhoneLegalclub,
  getPhoneHughesbc,
  getPhoneFortunebc,
  getPhoneUSAMedicalStaff,
  getPhoneHandyInc,
  getPhoneRebuildingTogether,
  getPhoneCheddarCap,
  getPhoneResourcingEdge,
  getPhoneGildasClub,
  getPhoneShieldAdvisory,
  getPhoneRichard,
  getPhoneMoneyVehicle,
  getPhoneQuadMeets,
  getPhoneEdwardJones,
  getPhoneCoAdvantage,
  getPhoneBillFixers,
  getPhoneKiddieKredit,
  getPhoneQoins,
  getPhoneBudgetnista,
  getPhonetTehFinancial,
  getPhonefinancialwellnesswithvicki,
  getPhoneMamaandmoney,
  getPhoneInspiredbudget,
};
