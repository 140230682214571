import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import LoaderStyles from './Loader.styles';

const Loader = ({ isLoading, children }) => (
  <LoaderStyles>
    <Spin spinning={isLoading}>{children}</Spin>
  </LoaderStyles>
);

Loader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
};

export default Loader;
