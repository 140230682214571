import styled from 'styled-components';
// import fromTheme from '../../helpers/fromTheme';

const CredibilityModalStyles = styled.div`
  .modal-overlay {
    top: 0;
    left: 0;
    opacity: 0.5;
    width: 100vw;
    height: 100vh;
    z-index: 1040;
    position: fixed;
    background-color: #000;
  }

  .modal-wrapper {
    left: 0;
    top: 10%;
    outline: 0;
    width: 100%;
    z-index: 1050;
    min-height: 60%;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .modal {
    z-index: 100;
    max-width: 800px;
    background: white;
    border-radius: 3px;
    position: relative;
    margin: 1.75rem auto;
  }

  .modal-header {
    display: flex;
    padding: 0.5rem;
    justify-content: flex-end;
  }

  .modal-content {
    display: flex;
    padding: 4.5rem;
    padding-top: 1rem;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    img {
      margin: 0 auto;
      max-width: 150px;
    }

    .modal-copy {
      display: flex;
      font-size: 23px;
      margin-bottom: 0;
      line-height: 1.3;
      font-weight: 100;
      text-align: center;
      padding: 2rem 4.5rem;
      flex-direction: column;
      font-family: 'Open Sans', sans-serif;
    }

    .modal-phone-copy {
      color: #1890ff;
      font-size: 25px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .ant-btn.modal-button {
      border: 0;
      width: 30%;
      height: 100%;
      display: flex;
      color: #1890ff;
      font-size: 25px;
      font-weight: 100;
      border-width: 2px;
      text-align: center;
      border-radius: 5px;
      align-items: center;
      border-style: solid;
      border-color: #1890ff;
      justify-content: center;
      text-transform: uppercase;
      background-color: transparent;
      &:hover {
        color: #fff;
        background-color: #1890ff;
      }
    }
  }

  @media all and (max-width: 815px) {
    .modal-wrapper {
      top: 3%;
      height: 90%;
    }

    .modal {
      max-width: 500px;
    }

    .modal-content {
      padding: 1rem;
      padding-top: 0;

      img {
        max-width: 100px;
      }

      .modal-copy {
        font-size: 16px;
        padding: 1rem;
      }

      .modal-phone-copy {
        font-size: 21px;
      }

      .ant-btn.modal-button {
        width: 70%;
        font-size: 21px;
      }
    }
  }

  .modal-close-button {
    color: #000;
    border: none;
    opacity: 0.8;
    line-height: 1;
    cursor: pointer;
    font-weight: 700;
    font-size: 1.8rem;
  }

  button {
    border: none;
    font-weight: 700;
    background: white;
    font-size: 0.9rem;
    border-radius: 3px;
    margin-left: 0.5rem;
    padding: 0.3rem 1rem;
  }

  .button-default {
    color: #fff;
  }
`;

export default CredibilityModalStyles;
